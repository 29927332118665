import React from "react";
import styled from "styled-components";
import meccaLogo from '../../images/logo.svg';

export function Logo(props) {
  return (
    <div className="logo-wrapper">
      <div className="logo-text">
        <a href="/#">THE MECCA PRO-AM</a>
      </div>
    </div>
  );
}
