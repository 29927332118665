import React from "react";
import { useMediaQuery } from "react-responsive";
import { Logo } from "../logo";
import { MobileNavLinks } from "./mobileNavLinks";
import { NavLinks } from "./navLinks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import "./navLinks.css";

const NavbarHeader = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div className="navbar">
      <div className="navbar--left-section">
        <a href="/shop"><FontAwesomeIcon icon={faCartShopping}/></a>
      </div>
      <div className="navbar--middle-section">{isMobile && <Logo />}{!isMobile && <span><a href="/#">THE MECCA PRO-AM</a></span>}</div>
      <div className="navbar--right-section">
        <MobileNavLinks />
      </div>
    </div>
  );
};

export default NavbarHeader;
